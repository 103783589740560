import React from 'react';
import { useParams } from 'react-router-dom';
import useGetInstructionalUnitBySlug from '@hoot/hooks/api/library/useGetInstructionalUnitBySlug';
import useGetPrioritizedSkillDocumentation from '@hoot/hooks/api/library/useGetPrioritizedSkillDocumentation';
import { usePageTitle } from '@hoot/hooks/usePageTitle';
import { ViewStateEnum } from '@hoot/ui/components/v2/ViewState';
import PageLayout from '@hoot/ui/components/v2/core/PageLayout';
import { useStudentDetailsContextUnsafe } from '@hoot/ui/pages/v2/teacher/my-students/student-details/StudentDetailsContextProvider';
import InstructionalUnitResourcesTable from '@hoot/ui/pages/v2/teacher/scope-and-sequence/focus-area/instructional-unit/documentation/InstructionalUnitResourcesTable';
import ScopeAndSequenceDocumentationTemplate from '@hoot/ui/pages/v2/teacher/scope-and-sequence/focus-area/instructional-unit/documentation/ScopeAndSequenceDocumentationTemplate';
import { FocusAreaSlug, scopeAndSequenceFocusAreaDictionary } from '@hoot/ui/pages/v2/teacher/scope-and-sequence/scopeAndSequenceFocusAreas';

const PrioritizedSkillDocumentation = () => {
  const { focusAreaId, unitSlug, skillId, studentProfileId } = useParams<{
    focusAreaId: FocusAreaSlug;
    unitSlug: string;
    skillId: string;
    studentProfileId?: string;
  }>();

  const focusArea = scopeAndSequenceFocusAreaDictionary[focusAreaId as FocusAreaSlug];
  const getInstructionalUnitRequest = useGetInstructionalUnitBySlug(unitSlug!);
  const getPrioritizedSkillDocumentationRequest = useGetPrioritizedSkillDocumentation(skillId!, { retry: false });

  const { studentProfileSnippet } = useStudentDetailsContextUnsafe() ?? {};
  const optionalStudentNamePrefix = studentProfileSnippet?.name ? `${studentProfileSnippet?.name} - ` : '';

  usePageTitle(`${getPrioritizedSkillDocumentationRequest.data?.prioritizedSkillFilter?.name ?? 'Prioritized SKill'} Documentation | Hoot Reading`);

  return (
    <>
      <ScopeAndSequenceDocumentationTemplate
        viewState={
          getPrioritizedSkillDocumentationRequest.isFetching
            ? ViewStateEnum.Loading
            : getPrioritizedSkillDocumentationRequest.error
              ? ViewStateEnum.Error
              : !getPrioritizedSkillDocumentationRequest.data?.documentationHtmlContent
                ? ViewStateEnum.NoResults
                : ViewStateEnum.Results
        }
        title={getPrioritizedSkillDocumentationRequest.data?.prioritizedSkillFilter?.name ?? null}
        navigateBackPath={'../..'}
        breadcrumbs={[
          'Hoot Scope & Sequence',
          focusArea.title,
          getInstructionalUnitRequest.data?.name ?? '-',
          getPrioritizedSkillDocumentationRequest.data?.prioritizedSkillFilter?.name ?? '-',
          'Documentation',
        ]}
        htmlContent={getPrioritizedSkillDocumentationRequest.data?.documentationHtmlContent ?? null}
        NoResultsIllustrationProps={{
          title: 'No documentation found',
          subtitle: 'There might not be any documentation for this prioritized skill',
        }}
      />
      {focusArea.slug !== FocusAreaSlug.textReading && getPrioritizedSkillDocumentationRequest.data?.prioritizedSkillFilter && (
        <PageLayout>
          <InstructionalUnitResourcesTable
            title={`${optionalStudentNamePrefix}${getPrioritizedSkillDocumentationRequest.data?.prioritizedSkillFilter.name} - Resources`}
            focusId={focusArea.instructionalFocusId}
            unitId={getPrioritizedSkillDocumentationRequest.data?.prioritizedSkillFilter.instructionalUnit.id}
            skillFilterId={skillId}
            studentProfileId={studentProfileId}
          />
        </PageLayout>
      )}
    </>
  );
};

export default PrioritizedSkillDocumentation;
