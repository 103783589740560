import { TeacherStatus } from '@hoot-reading/hoot-core/dist/enums/user/teacher/teacher-status.enum';
import { featureFlags } from '@hoot/constants/featureFlags';
import { TeacherStage } from '@hoot/models/api/teacher';
import { externalRoutes } from '@hoot/routes/externalRoutes';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import { NavList } from '@hoot/ui/components/v2/core/NavMenuList';
import { useFeatureFlags } from '@hoot/ui/context/FeatureFlagContext';
import { LIVE_CHAT_TITLE, zendeskOpenLiveChat } from '@hoot/utils/zendesk-methods';
import NewTabIcon from '../components/v2/icons/NewTabIcon';
import { useAuth } from '../context/AuthContext';

interface TeacherNavOptions {
  isTeacherProfileDisabled: boolean;
  showCookieConsentModal: () => void;
  showAudioVideoSettingsModal: () => void;
}

const useTeacherNavItems = (options: TeacherNavOptions): NavList[] => {
  const { isTeacherProfileDisabled, showCookieConsentModal, showAudioVideoSettingsModal } = options;

  const { isFeatureEnabled } = useFeatureFlags();
  const isScopeAndSequenceEnabled = isFeatureEnabled(featureFlags.epic_8641_scope_and_sequence);

  const { getUser } = useAuth();
  const user = getUser();
  const teacherStage = user.teacherAccount?.teacherStage;
  const teacherStatus = user.teacherAccount?.status;

  // Disable flags.
  const isStatusCandidate = teacherStatus === TeacherStatus.Candidate;
  const isStatusInactive = teacherStatus === TeacherStatus.Inactive;
  const teacherDisabledOrCandidate = isTeacherProfileDisabled || isStatusCandidate;
  const teacherDisabledOrNotHired = isTeacherProfileDisabled || teacherStage === TeacherStage.NotHired;

  const navItems: NavList[] = [
    { label: routesDictionary.dashboard.label, url: routesDictionary.dashboard.url, disabled: teacherDisabledOrNotHired },
    {
      label: routesDictionary.myStudents.label,
      url: routesDictionary.myStudents.url,
      disabled: teacherDisabledOrNotHired || isStatusInactive,
    },
    {
      label: routesDictionary.schedule.label,
      disabled: isTeacherProfileDisabled,
      subList: [
        { label: 'Calendar', url: routesDictionary.schedule.url, disabled: isTeacherProfileDisabled },
        {
          label: routesDictionary.opportunities.label,
          url: routesDictionary.opportunities.url,
          disabled: teacherDisabledOrCandidate || isStatusInactive,
        },
      ],
    },
    {
      label: routesDictionary.invoicing.label,
      url: routesDictionary.invoicing.url,
      disabled: isStatusCandidate || isTeacherProfileDisabled,
    },
    {
      label: 'Learning & Support',
      subList: [
        {
          label: 'Library Sandbox',
          url: routesDictionary.library.url,
          disabled: teacherDisabledOrCandidate || isStatusInactive,
        },
        ...(isScopeAndSequenceEnabled
          ? [
              {
                label: 'Scope and Sequence',
                url: routesDictionary.scopeAndSequence.url,
                disabled: teacherDisabledOrCandidate || isStatusInactive,
              },
            ]
          : []),
        {
          label: 'Teacher Help Center',
          url: externalRoutes.zendeskHelpCenter.url,
          external: true,
          disabled: isStatusCandidate || isTeacherProfileDisabled,
        },
        { label: LIVE_CHAT_TITLE, url: '', onClick: () => zendeskOpenLiveChat(), disabled: teacherDisabledOrNotHired },
      ],
    },
    {
      label: 'Settings',
      subList: [
        {
          label: routesDictionary.profile.label,
          url: routesDictionary.profile.url,
          disabled: isStatusCandidate || isTeacherProfileDisabled,
        },
        {
          label: routesDictionary.settings.audioVideo.label,
          url: '',
          onClick: showAudioVideoSettingsModal,
          hideOnMobile: true,
          disabled: teacherDisabledOrNotHired,
        },
        { label: 'Manage Cookies', url: '', onClick: showCookieConsentModal, disabled: teacherDisabledOrNotHired },
        {
          label: externalRoutes.dailyNetworkTest.label,
          url: externalRoutes.dailyNetworkTest.url,
          external: true,
          icon: <NewTabIcon />,
          hideOnMobile: true,
          disabled: teacherDisabledOrNotHired,
        },
      ],
    },
  ];
  // A little hacky, but we need to remove any undefined items here.
  return navItems.filter((x) => !!x) as NavList[];
};

export default useTeacherNavItems;
