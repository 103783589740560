import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, Stack, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { DateFormats } from '@hoot/constants/constants';
import { featureFlags } from '@hoot/constants/featureFlags';
import {
  GetStudentTextReadingsQuery,
  HootTextReadingResponse,
  StudentTextReadingsQuerySortKeyEnum,
  useGetTextReadings,
} from '@hoot/hooks/api/assessment/useGetStudentTextReadings';
import { OrderBy } from '@hoot/models/api/enums/queryEnums';
import { DEFAULT_PAGE_SIZE, GenericPaginatedResponse } from '@hoot/models/api/pagination';
import { routesDictionary } from '@hoot/routes/routesDictionary';
import ViewStateIllustration, { IllustrationEnum } from '@hoot/ui/components/v2/ViewStateIllustration';
import Card from '@hoot/ui/components/v2/core/Card';
import HootTypography from '@hoot/ui/components/v2/core/HootTypography';
import { Icon } from '@hoot/ui/components/v2/core/Icon';
import { HeaderData, TableV2 } from '@hoot/ui/components/v2/core/Table';
import Chevron from '@hoot/ui/components/v2/icons/Chevron';
import { useFeatureFlags } from '@hoot/ui/context/FeatureFlagContext';
import { scopeAndSequenceFocusAreaDictionary } from '@hoot/ui/pages/v2/teacher/scope-and-sequence/scopeAndSequenceFocusAreas';
import { hootTokens } from '@hoot/ui/theme/v2/tokens';

interface TextReadingTableRow {
  assessmentId: string;
  startDate: string;
  bookTitle: string;
  bookLevel: string;
  fluency: string;
  vocabulary: string;
  textSpecificKnowledge: string;
  verbalReasoning: string;
  textStructure: string;
  sentenceAnalysis: string;
}

const headers: HeaderData<TextReadingTableRow>[] = [
  { name: 'Start Date', property: 'startDate', isSortable: true, width: '15%' },
  { name: 'Title of Book', property: 'bookTitle', isSortable: false, width: '15%' },
  { name: 'Book Level', property: 'bookLevel', isSortable: false, width: '10%' },
  { name: 'Fluency', property: 'fluency', isSortable: false, width: '10%' },
  { name: 'Vocabulary', property: 'vocabulary', isSortable: false, width: '10%' },
  { name: 'Text-Specific Knowledge', property: 'textSpecificKnowledge', isSortable: false, width: '10%' },
  { name: 'Verbal Reasoning', property: 'verbalReasoning', isSortable: false, width: '10%' },
  { name: 'Text Structure', property: 'textStructure', isSortable: false, width: '10%' },
  { name: 'Sentence Analysis', property: 'sentenceAnalysis', isSortable: false, width: '10%' },
];

const mobileHeaders: HeaderData<TextReadingTableRow>[] = [
  { name: 'Start Date', property: 'startDate', isSortable: true },
  { name: 'Title of Book', property: 'bookTitle', isSortable: false },
  { name: 'Book Level', property: 'bookLevel', isSortable: false },
];

const StudentTextReadingsCard = () => {
  const { studentProfileId } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const textReadingFocusArea = scopeAndSequenceFocusAreaDictionary['text-reading'];

  const { isFeatureEnabled } = useFeatureFlags();
  const isScopeAndSequenceEnabled = isFeatureEnabled(featureFlags.epic_8641_scope_and_sequence);

  const [query, setQuery] = useState<GetStudentTextReadingsQuery>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    orderBy: StudentTextReadingsQuerySortKeyEnum.StartDate,
    sortDirection: OrderBy.Desc,
  });

  const [results, setResults] = useState<GenericPaginatedResponse<TextReadingTableRow>>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    data: [],
    count: 0,
  });

  const getTextReadings = useGetTextReadings(studentProfileId!, query, {
    enabled: !!studentProfileId,
    onSuccess: (response) => {
      setResults({
        ...response,
        data: mapResponseToTableRows(response.data),
      });
    },
  });

  const mapResponseToTableRows = (textReadings: HootTextReadingResponse[]): TextReadingTableRow[] => {
    return textReadings.map<TextReadingTableRow>((x): TextReadingTableRow => {
      return {
        assessmentId: x.assessmentId,
        startDate: x.startDate ? DateTime.fromMillis(x.startDate).toFormat(DateFormats.FULL_MDY_0) : '-',
        bookTitle: x.bookTitle ?? '-',
        bookLevel: x.bookLevel ?? '-',
        fluency: x.fluency ?? '-',
        vocabulary: x.vocabulary ?? '-',
        textSpecificKnowledge: x.textSpecificKnowledge ?? '-',
        verbalReasoning: x.verbalReasoning ?? '-',
        textStructure: x.textStructure ?? '-',
        sentenceAnalysis: x.sentenceAnalysis ?? '-',
      };
    });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const pageSize = parseInt(event.target.value, 10);
    setQuery((currentState) => ({ ...currentState, page: 1, pageSize }));
  };

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setQuery((currentState) => ({ ...currentState, page: newPage + 1 }));
  };

  const handleSortBy = (selectedColumn: keyof TextReadingTableRow) => {
    const getQueryOrderByColumn = (): StudentTextReadingsQuerySortKeyEnum => {
      switch (selectedColumn) {
        case 'startDate':
          return StudentTextReadingsQuerySortKeyEnum.StartDate;
        default:
          return StudentTextReadingsQuerySortKeyEnum.StartDate;
      }
    };
    const queryOrderByColumn = getQueryOrderByColumn();
    setQuery((currentState) => ({
      ...currentState,
      orderBy: queryOrderByColumn,
      sortDirection:
        queryOrderByColumn !== currentState.orderBy ? OrderBy.Asc : currentState.sortDirection === OrderBy.Asc ? OrderBy.Desc : OrderBy.Asc,
    }));
  };

  function orderByColumn(): keyof TextReadingTableRow {
    switch (query.orderBy) {
      case StudentTextReadingsQuerySortKeyEnum.StartDate:
        return 'startDate';
      default:
        return 'startDate';
    }
  }

  if (!results.data) {
    return null;
  }

  const handleInfoClick = () => {
    window.open('https://hootteachers.zendesk.com/hc/en-us/sections/16596694131604-Text-Reading', '_blank');
  };

  return (
    <Card isLoading={getTextReadings.isFetching}>
      <Accordion elevation={0} defaultExpanded sx={{ p: 0 }}>
        <AccordionSummary expandIcon={<Chevron sx={{ rotate: '90deg' }} />}>
          <Stack gap={2} direction="row" alignItems="center">
            {isScopeAndSequenceEnabled && textReadingFocusArea?.slug && studentProfileId && (
              <Tooltip title={`View documentation on "Text Reading"`}>
                <Link
                  to={routesDictionary.myStudents.details.scopeAndSequence.focusArea.url(studentProfileId!, textReadingFocusArea?.slug)}
                  target="_blank"
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '49px',
                      height: '49px',
                      borderRadius: '8px',
                      border: '1px solid',
                      borderColor: hootTokens.surface[4],
                      background: hootTokens.palette.white,
                      boxShadow: hootTokens.elevation.elevation2,
                    }}
                  >
                    <img src="/images/hoot-shapes.svg" alt="scope-and-sequence-logo" height="33px" width="33px" />
                  </Box>
                </Link>
              </Tooltip>
            )}
            <HootTypography isPII={false} variant="titlemedium">
              Text Reading
            </HootTypography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ mt: 2 }}>
          <Stack gap={2}>
            <Stack direction="row" alignItems="center">
              <HootTypography isPII={false} variant="bodylarge">
                Instructional Pathways
              </HootTypography>
              <IconButton onClick={handleInfoClick}>
                <Icon name="info_filled" color="primary.0" />
              </IconButton>
            </Stack>
            <TableV2
              isPaginated
              isSortable
              data={results.data}
              headers={isMobile ? mobileHeaders : headers}
              onRowsPerPageChange={handleChangeRowsPerPage}
              count={results.count}
              page={query.page - 1}
              onPageChange={handleChangePage}
              onSortBy={handleSortBy}
              sortBy={orderByColumn()}
              sortOrder={query.sortDirection ?? OrderBy.Asc}
              rowsPerPage={query.pageSize}
              emptyViewState={<ViewStateIllustration illustration={IllustrationEnum.EmptyState} />}
            />
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

export default StudentTextReadingsCard;
